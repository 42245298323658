
import { IonContent, IonHeader, IonToolbar, IonButtons, IonButton, IonMenuButton, IonPage, IonRow, IonCol, IonLabel, IonTitle, IonItem, IonInput, IonSpinner, IonChip, IonCardSubtitle } from '@ionic/vue';
import axios from 'axios';
import { defineComponent, ref } from 'vue';
import moment from 'moment';

export default defineComponent({
  name: 'Login',
  data () {
    return {
      inserat: null,
      userName: null,
      userPassword: null,
      isLoading: false,
      authError: false
    }
  },
  components: {
    IonContent,
    IonPage,
    IonRow,
    IonCol,
    IonLabel,
    IonToolbar,
    IonHeader,
    IonMenuButton,
    IonButtons,
    IonButton,
    IonTitle,
    IonItem,
    IonInput,
    IonSpinner,
    IonChip,
    IonCardSubtitle
  },
  methods: {
    convertCreatedAt(date: string){
      return moment(date).format("DD.MM.Y");
    },
    convertCreatedAtTime(date: string){
      return moment(date).format("HH:MM");
    },
    clearUserCookies(){
      localStorage.clear();
    },
    updateSideMenu(redirect = false, isVerified = true) {

      console.log("Token Request Completed. User: " + localStorage.userName);

      window.dispatchEvent(new CustomEvent('user-state-changed', {
        detail: {
          userId: localStorage.getItem('userId'),
          userName: localStorage.getItem('userName'),
          userToken: localStorage.getItem('userToken'),
          userRoles: localStorage.getItem('userRoles'),
          userIsLoggedIn: true
        }
      }));

      if (localStorage.userToken && redirect && isVerified){
        // redirect to main page
        this.$router.push({ path: '/all' })
      }

      if (!isVerified) {
        // redirect to confirm page
        this.$router.push({ path: '/register/confirm' })
      }

    },
    loginAction(){

      this.isLoading = true;

      // 1. retrieve token
      console.log('1. Retrieving Token.')
      axios.post(process.env.VUE_APP_API_URL + '/authentication_token', {
        username : this.userName,
        password : this.userPassword
      })
          .then(authResponse => {

            const userId = authResponse.data.data.id;
            const userToken = authResponse.data.token;
            const refreshToken = authResponse.data.refresh_token;

            console.log('2. Getting User Data.')

            // 2. get user data
            axios.get(process.env.VUE_APP_API_URL + '/users/' + userId, {
              headers: {
                'Authorization': `Bearer ` + userToken
              }
            })
                .then(response => {
                  console.log("3. Saving User Data.");

                  // store user id
                  localStorage.userId = response.data.uuid;
                  // store username
                  localStorage.userName = response.data.username;
                  // store user token
                  localStorage.userToken = userToken;
                  // store user roles
                  localStorage.userRoles = response.data.roles;
                  // store refresh token
                  localStorage.refreshToken = refreshToken;
                  // store verification
                  localStorage.userIsVerified = response.data.isVerified;

                  // redirect to all inserat
                  localStorage.redirectedToAllInserats = false;

                  this.updateSideMenu(true, response.data.isVerified);

                })
                .catch(error => {
                  console.log("3. Could not save User Data.", error);
                  // clear local storage
                  this.clearUserCookies();
                  this.authError = true;
                })
                .finally(() => {
                    this.isLoading = false;
                })


          })
          .catch(error => {

            console.log("2. Could not get User Data.");

            // clear local storage
            this.clearUserCookies();
            this.authError = true;
            this.isLoading = false;
          });



    }
  }
});

