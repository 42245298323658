<template>
  <ion-page>

    <ion-header :translucent="true" class="ion-no-border">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title></ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true" v-if="!isLoading">

      <ion-row class="sideMargins topMargins">

        <ion-col size="12" v-if="authError">
          <ion-chip color="danger">
            <ion-label color="danger">E-Mail oder Passwort ist falsch.</ion-label>
          </ion-chip>
        </ion-col>

        <ion-col size="12">
          <ion-label class="title" style="margin-left: 17px;">Einloggen</ion-label>
        </ion-col>
        <ion-col size="12">
          <ion-item>
            <ion-label position="stacked" placeholder="Benutzername">Benutzername</ion-label>
            <ion-input v-model="userName"></ion-input>
          </ion-item>
        </ion-col>
        <ion-col size="12">
          <ion-item>
            <ion-label position="stacked" placeholder="Passwort">Passwort</ion-label>
            <ion-input v-model="userPassword" type="password"></ion-input>
          </ion-item>
        </ion-col>
        <ion-col size="12">
          <ion-button expand="block" style="margin-left: 17px;" @click="loginAction()">Log In</ion-button>
        </ion-col>
        <ion-col size="12" style="margin-top: 35px;">
          <ion-card-subtitle style="margin-left: 17px;">Du hast noch keinen Account?</ion-card-subtitle>
          <ion-button expand="block" style="margin-left: 17px;" color="light" router-link="/register">Jetzt Registrieren</ion-button>
        </ion-col>
        <ion-col size="12">
          <ion-card-subtitle style="margin-left: 17px;">Du hast dein Passwort vergessen?</ion-card-subtitle>
          <ion-button expand="block" style="margin-left: 17px;" color="light" @click="loginAction()">Passwort zurücksetzen</ion-button>
        </ion-col>
      </ion-row>

    </ion-content>

    <ion-content v-if="isLoading" class="spinnerCenter">
      <ion-spinner name="dots"></ion-spinner>
    </ion-content>

  </ion-page>
</template>

<script lang="ts">
import { IonContent, IonHeader, IonToolbar, IonButtons, IonButton, IonMenuButton, IonPage, IonRow, IonCol, IonLabel, IonTitle, IonItem, IonInput, IonSpinner, IonChip, IonCardSubtitle } from '@ionic/vue';
import axios from 'axios';
import { defineComponent, ref } from 'vue';
import moment from 'moment';

export default defineComponent({
  name: 'Login',
  data () {
    return {
      inserat: null,
      userName: null,
      userPassword: null,
      isLoading: false,
      authError: false
    }
  },
  components: {
    IonContent,
    IonPage,
    IonRow,
    IonCol,
    IonLabel,
    IonToolbar,
    IonHeader,
    IonMenuButton,
    IonButtons,
    IonButton,
    IonTitle,
    IonItem,
    IonInput,
    IonSpinner,
    IonChip,
    IonCardSubtitle
  },
  methods: {
    convertCreatedAt(date: string){
      return moment(date).format("DD.MM.Y");
    },
    convertCreatedAtTime(date: string){
      return moment(date).format("HH:MM");
    },
    clearUserCookies(){
      localStorage.clear();
    },
    updateSideMenu(redirect = false, isVerified = true) {

      console.log("Token Request Completed. User: " + localStorage.userName);

      window.dispatchEvent(new CustomEvent('user-state-changed', {
        detail: {
          userId: localStorage.getItem('userId'),
          userName: localStorage.getItem('userName'),
          userToken: localStorage.getItem('userToken'),
          userRoles: localStorage.getItem('userRoles'),
          userIsLoggedIn: true
        }
      }));

      if (localStorage.userToken && redirect && isVerified){
        // redirect to main page
        this.$router.push({ path: '/all' })
      }

      if (!isVerified) {
        // redirect to confirm page
        this.$router.push({ path: '/register/confirm' })
      }

    },
    loginAction(){

      this.isLoading = true;

      // 1. retrieve token
      console.log('1. Retrieving Token.')
      axios.post(process.env.VUE_APP_API_URL + '/authentication_token', {
        username : this.userName,
        password : this.userPassword
      })
          .then(authResponse => {

            const userId = authResponse.data.data.id;
            const userToken = authResponse.data.token;
            const refreshToken = authResponse.data.refresh_token;

            console.log('2. Getting User Data.')

            // 2. get user data
            axios.get(process.env.VUE_APP_API_URL + '/users/' + userId, {
              headers: {
                'Authorization': `Bearer ` + userToken
              }
            })
                .then(response => {
                  console.log("3. Saving User Data.");

                  // store user id
                  localStorage.userId = response.data.uuid;
                  // store username
                  localStorage.userName = response.data.username;
                  // store user token
                  localStorage.userToken = userToken;
                  // store user roles
                  localStorage.userRoles = response.data.roles;
                  // store refresh token
                  localStorage.refreshToken = refreshToken;
                  // store verification
                  localStorage.userIsVerified = response.data.isVerified;

                  // redirect to all inserat
                  localStorage.redirectedToAllInserats = false;

                  this.updateSideMenu(true, response.data.isVerified);

                })
                .catch(error => {
                  console.log("3. Could not save User Data.", error);
                  // clear local storage
                  this.clearUserCookies();
                  this.authError = true;
                })
                .finally(() => {
                    this.isLoading = false;
                })


          })
          .catch(error => {

            console.log("2. Could not get User Data.");

            // clear local storage
            this.clearUserCookies();
            this.authError = true;
            this.isLoading = false;
          });



    }
  }
});

</script>

<style scoped>

ion-spinner {
  display: block;
  margin: auto;
  margin-top: 50%;
}

.imageCircle {
  border: 2px solid #1dc0c6;
}

.rounded-image {
  border-radius: 50%;
}

.sideMargins {
  margin-left: 25px;
  margin-right: 25px;
}

.topMargins {
  margin-top: 25%;
}

.spinnerCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.bottomMargins {
  margin-bottom: 25px;
}

.title {
  font-size: 24px;
  font-weight: bold;
}

.price {
  font-size: 20px;
  font-weight: bold;
}

.roundedCorners {
  border-radius: 10px;
}

</style>